import * as React from 'react'
import { ExternalLink } from './ExternalLink'
import { XRPChartsLogo } from '../../assets/assets'

export class ExternalLinkContainer extends React.PureComponent<{}, {}> {
	public render(): JSX.Element | null {
		return (
			<div className="external-container">
				<ExternalLink
					iconUrl={XRPChartsLogo}
					linkTo="https://xrpcharts.ripple.com/#/validators"
				/>
			</div>
		)
	}
}
