import * as React from 'react'
import { ExternalLinkContainer } from './external_sites/ExternalLinkContainer'
import './../assets/scss/App.scss'

export interface AppProps {}

export default class App extends React.Component<AppProps, undefined> {
	render(): JSX.Element | null {
		return (
			<div className="app">
				<div className="app__vertical-container">
					<div className="app__centered-container">
						<ExternalLinkContainer />
					</div>
				</div>
			</div>
		)
	}
}
