import * as React from 'react'

export interface ExternalLinkProps {
	iconUrl: string
	linkTo: string
}

export class ExternalLink extends React.Component<ExternalLinkProps, {}> {
	private onClick(): void {
		window.open(this.props.linkTo, '__blank')
	}

	public render(): JSX.Element | null {
		return (
			<div className="external-link">
				<img
					className="external-link__icon"
					onClick={this.onClick.bind(this)}
					src={this.props.iconUrl}
					height="30"
				/>
			</div>
		)
	}
}
